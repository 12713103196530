import Vue from 'vue'

import Service from "../Service";

const resource="dinningHall/menutypeworker/";

export default {
    pagination(dtr){return Vue.prototype.$http.post(resource + "workerpagination", dtr);},
 
    list(obj, requestID) {
        return Service.post(resource + "workerlist", obj, {
            params: { requestID: requestID },
        });
    },
}
