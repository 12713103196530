<template>
    <v-card >
        <row>            
            <v-col sm="12" md="6" >
                <s-toolbar
                    label="Personal"
                    dark
                    color="#8E8F91"
                    close
                    save
                    @save="save()"
                />
                <s-select-area
                    v-model="filter.AreID"
                />
                <v-data-table
                    item-key="WkrID"
                    dense
                    v-model="selected"
                    :items-per-page="10"
                    :items="workerItems"
                    :headers="headerworkerItems"
                    show-select
                    disable-sort 
                    >

                </v-data-table>
                  
            </v-col>
            <v-col  sm="12" md="6"  >
                <!--:refreshTable="refresh" @save="assing($event)" @removed="removedMenu()"  :filter="filter"-->
                <s-crud 
                    :config="configAssingned"  
                    excel
                    edit
                    remove
                    title="Trabajadores Asignados" 
                >

                </s-crud>   
            </v-col>
        </row>
    </v-card>

</template>

<script>

    import _MenuWorkerUnassingnedService from "./../../services/DinningHall/MenuWorkerUnassingnedService";
    import _AssingnBreakfastService from "./../../services/DinningHall/AssingnBreakfastService";

    export default {

        data: () => ({
            filter: {
                AreID: null,
            },
            workerItems: [],
            headerworkerItems: [
                { text: "ID", value: "WkrID" },
                { text: "Trabajador", value: "PrsFullName" },
                { text: "Documento", value: "PrsDocumentNumber" },
                { text: "Area", value: "AreName" },
            ],
            selected: [],
            configDriverVehicle: {
				model: {
					AdvID: "ID",
					 
				},
				service: _MenuWorkerUnassingnedService,
				headers: [ 
					{ text: "ID", value: "WkrID" },
                    { text: "Trabajador", value: "PrsFullName" },
                    { text: "Documento", value: "PrsDocumentNumber" },
                    { text: "Area", value: "AreName" },
				],
			},
        }),
        mounted(){
            _MenuWorkerUnassingnedService.list(this.filter, this.$fun.getUserID())
                .then((r) => {
                    console.log("res", r.data)
                    this.workerItems = r.data;
                });
        },    
        watch: {
            "filter.AreID"(){
                console.log("res war" )
                this.selected = [];
                _MenuWorkerUnassingnedService.list(this.filter, this.$fun.getUserID())
                .then((r) => {
                    
                    this.workerItems = r.data;
                });
            }
        },
        methods: {
            save(){
                console.log("save" ,this.selected)

            }
        }
    }

</script>